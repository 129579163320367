// For variant interpretation Case/Segregation tab master/tally table
.masterTable {
  width: 100%;
  display: block;
  overflow-x: scroll;

  .code {
    word-break: keep-all;
  }

  .evidence-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .evidence-links {
    display: flex;
    justify-content: flex-end;
  }

  .btn.master-icon {
    padding: 2px 6px;
  }

  .more-text-div {
    cursor: default;
    text-decoration: none;
  }

  .more-text {
    color: #337ab7;
    font-weight: bold;
    text-align: center;
  }
}
.masterTable tbody tr td:nth-child(2) > div {
  width: 230px;
  min-height:37px;
  max-height:77px;
}
.masterTable thead tr th:nth-child(n+2),
.masterTable tbody tr td:nth-child(n+2) {
  min-width: 100px;
  max-width: 250px;
}
.masterTable thead tr:nth-child(2) th:nth-child(2),
.masterTable thead tr:nth-child(n+1) th:nth-child(n+3),
.masterTable tbody tr td:nth-child(n+3) {
  background-color: $table-row-odd-bg;
}
.masterTable thead tr:nth-child(n+1) th:nth-child(2n+3),
.masterTable tbody tr td:nth-child(2n+3) {
  background-color: $table-row-even-bg;
}
.masterTable thead tr th.rotate {
  height: 400px;
  white-space: nowrap;
  vertical-align: inherit;
  border: none;
}
.masterTable thead tr th.rotate > div {
  transform: translate(20px, 177px) rotate(-45deg);
  width: 30px;
}
.masterTable thead tr th.rotate > div > span {
  border-top: 1px solid #ddd;
  padding-bottom: 10px;
  padding-left: 33px;
  margin-left: -33px;
  padding-top: 4px;
}

// For variant interpretation Case/Segregation tab evidence tables
.evidenceTable thead tr th,
.evidenceTable tbody tr td {
  min-width: 100px;
}

.details-modal-width {
  max-width: 90%;
}

// For HPO with terms btns and label in Add Evidence Modal
.terms-label {
  padding-bottom: 40px;
}

// For relevant fields
input[type=text],
input[type=number] {
  &.relevant-field {
    background-color: #00FF0030;
  }
}
