.navbar {
    margin: 0;
    padding:1rem;
    background:#1b75bc;
    .nav-link, .btn-outline-primary{
        color:#fff;
    }
    .btn-outline-primary{border-color:#fff;}
}

.navbar-brand {
    display: block;
    @extend .navbar-brand;
    @extend .text-hide;
    padding: 0;
    height:40px;
    width:220px;
    background: url(../../img/ClinGen_NavbarLogo_White.svg) 0 0 no-repeat;
    background-size:cover;
}

.navbar-main {
    position: relative;

    .container {
        position: relative;
        z-index: 10;
    }
}

.navbar-main-bg {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 0;
}

.nav-main {
    @include media-breakpoint-up(sm) {
        position: absolute;
        bottom: 0;
        right: $grid-gutter-width / 2;
        font-size: 1.1rem;
        font-weight: 300;
    }
}

.navbar-default .navbar-nav > li > a {
    color: #000;

    &:hover {
        background-color: white;
    }
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.navbar-default {
    .navbar-toggle {
        border: none;
        margin: 16px 0 0 0;

        .icon-bar {
            background-color: $color-link;
            height: 4px;
        }
    }
}

.navbar-user {
    display: table;
    width: 100%;

    @include media-breakpoint-up(sm) {
        display: block;
        position: absolute;
        top: 0;
        right: 30px;
        width: auto;
        z-index: 100;
    }
}

.nav-user {
    display: table-row;

    li.link {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        padding: 1px 0 0 1px;

        a, a:focus {
            padding-top: 5px;
            padding-bottom: 5px;
            color: #fff;
            background-color: $color-trim;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                background-color: darken($color-trim, 10%);
            }
        }

        @include media-breakpoint-up(sm) {
            display: block;
            padding-top: 0;
        }

        &:first-child {
            margin-right: 10px;
        }
    }

    li.white-space {
        margin-left: 30px;
    }

    @include media-breakpoint-up(sm) {
        display: block;
        float: none;
    }

    li.link .dropdown {
        .dropdown-toggle {
            position: relative;
            display: block;
            padding: 5px 15px;
            line-height: 20px;

            .caret {
                margin-left: 6px;
            }
        }

        .dropdown-menu {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            display: block;
            left: 0;
            right: auto;
            margin-top: 0px;

             li a {
                color: #666;
                background-color: transparent;
            }

            li a:hover {
                color: #000;
                background-color: #ECECEC;
            }
        }
    }
}

// For a divider between a link and rest of links
.nav-item.border-light{
    border-color:rgba(255,255,255,0.2) !important;
}