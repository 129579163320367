/* case-cohort, control-cohort panels */
.case-cohort,
.control-cohort,
.case-control-eval-score,
.case-control-label-panel {

  .hpo-term {
    padding-bottom: 5px;
  }

  .card-header {
    display: block;
    padding: 5px 10px;
    margin: 0;
    background-color: $color-trim;
    color: #fff;
    text-decoration: none;
  }

  .card-body {
    background-color: $panel-body-bg;
  }

  .control-label {
    text-align: right;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 5px; // Default padding plus a border

    .normal {
      font-weight: normal;
    }
  }

  .control-demo-header {
    margin-top: 34px;
  }

  .section {
    h3 {
      border-bottom: solid 1px lighten(#486e82, 35%);
      color: #486e82;
      font-size: 1.45em;
      font-weight: 500;
      margin: 0 0 30px 0;
      padding: 5px 0;

      .label {
        font-size: 50%;
        margin-left: 5px;
        position: relative;
        top: 4px;
        vertical-align: super;
        display: inline;
        padding: .2em .6em .3em;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        border-radius: .25em;
      }
    }

    h4 {
      font-size: 1.25em;

      @media screen and (max-width: $screen-xs-max) {
        font-style: italic;
        margin-bottom: 20px;
        padding-left: 0;
      }
    }

    label {
      padding-right: 0;
    }

    .invisible-placeholder {
      visibility: hidden;

      @media screen and (max-width: $screen-xs-max) {
        display: none;
      }
    }

    &.section-power .form-group.allele-frequency {
      .col-sm-5 {
        padding-right: 0;
        text-align: right;

        span {
          font-weight: bold;
        }
      }
    }
  }

  .section:first-child {
    border-top: none;
  }

  .section-label {
    min-height: 200px;

    .input-note-below {
      margin: 0 0 30px 0;
      float: right;
      color: $label-note;
    }
  }
}

.case-cohort {

  .label-group {
    background-color: #f0ad4e;
  }

  .section-disease {    
    .add-disease-group .add-disease {
      .add-disease-button-group {
        li {
          display: inherit;

          &:nth-child(even) {
            margin: 4px 0;
          }

          .gdm-disease-copy>.btn,
          .evidence-disease>.btn {
            font-size: 13px;
            padding: 6px 8px;
          }
        }
      }

      .delete-disease-button {
        .btn.pull-right {
          float: left !important;
          margin-top: 10px;
        }
      }
    }
  }
}

.control-cohort {
  .section-disease {
    visibility: hidden;

    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }

    .add-disease-group .add-disease {
      .add-disease-button-group {
        li {
          display: inherit;

          &:nth-child(even) {
            height: 8px;
            visibility: hidden;
          }

          .gdm-disease-copy>.btn,
          .evidence-disease>.btn {
            font-size: 13px;
            opacity: 0.5;
            padding: 6px 8px;
            pointer-events: none;
          }
        }
      }

      .delete-disease-button.pull-right {
        float: left !important;
        padding-top: 10px;
      }
    }
  }

  .section-additional-info .other-genes {
    visibility: hidden;

    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .label-group {
    background-color: #91b6ca;
  }
}

.case-control-eval-score {
  .section-statistics {
    position: relative;

    h4 {
      @media screen and (min-width: $screen-sm-min) {
        position: absolute;
        margin-left: 0;
      }

      @media screen and (max-width: $screen-xs-max) {
        font-style: italic;
        margin-bottom: 20px;
        padding-left: 0;
      }
    }
  }

  //.statistic-other-type.expanded {
  //  display: block;
  //}

  .statistic-other-type.collapsed {
    display: none;
  }
}

.case-control-label-panel {
  .panel-std {
    padding-bottom: 10px;

    .form-group {
      margin-bottom: 0;
    }
  }
}


.case-control-content-viewer {
  .col-sm-12, .col-sm-6 {
    float: left;
  }
}

.case-cohort-view,
.control-cohort-view {
  @media screen and (max-width: $screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }

  .card-text {
    dt {
      width: auto;
      text-align: left;
      white-space: normal;
      vertical-align: top;

      @media screen and (min-width: $screen-lg-min) {
        min-width: 300px;
      }

      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        min-width: 250px;
      }

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        min-width: 135px;
      }
    }

    dd {
      width: 40%;
      vertical-align: top;
    }
  }
}

.case-control-evaluation {
  .panel-std {
    dt {
      width: auto;
      text-align: left;
      white-space: normal;
      vertical-align: top;

      @media screen and (min-width: $screen-lg-min) {
        min-width: 400px;
      }

      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        min-width: 350px;
      }

      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        min-width: 300px;
      }
    }

    dd {
      width: 45%;
      vertical-align: top;
    }
  }
}

.case-control-evidence-score {
  .card-header {
    display: block;
    padding: 5px 10px;
    margin: 0;
    background-color: $color-trim;
    color: #fff;
    text-decoration: none;
  }

  .card-body {
    background-color: $panel-body-bg;
  }

  .control-label {
    text-align: right;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 5px; // Default padding plus a border
  }

  .score-alert-message {
    padding-right: 0;
    float: right;

    .alert-warning {
      color: #8a6d3b;
      padding: 10px 15px;
    }
  }

}

.case-cohort-view {
  padding-left: 0;
}

.control-cohort-view {
  padding-right: 0;
}

.panel-body.panel-std {
  .section {
    h3 {
      border-bottom: solid 1px lighten(#486e82, 35%);
      color: #486e82;
      font-size: 1.45em;
      font-weight: 500;
      margin: 0 0 30px 0;
      padding: 5px 0;
    }

    .alert-warning {
      color: #8a6d3b;
    }

    .lod-score-inclusion-note {
      margin-top: -15px;
    }
  }

  .variant-assessment {
    .alert {
      padding: 8px 15px;
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  .proband-label-note {
    .alert {
      padding: 8px 15px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .score-status-note {
    .alert {
      padding: 8px 15px;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .sex-field-note {
    .alert {
      margin-top: -15px;
    }
  }

  .variant-panel .mutalyzer-link {
    margin-bottom: 10px;
  }

  .gene-ontology.help-text.style-list {
    padding-left: 14px;
  }

  dl.calculated-score {
    width: 100%;
    margin-bottom: 15px;
    padding-left: 4px;

    dt {
      width: 41.66667%;
    }

    dd {
      width: 58.33333%;
      padding-left: 15px;
    }
  }

  .evidence-score-list-viewer {
    margin-top: 10px;
    margin-bottom: 10px;

    h5 {
      margin-top: 0;
      padding-bottom: 5px;
      font-size: 1.25rem;
      border-bottom: 1px solid #ddd;

    }
  }

  .variant-view-panel-edit {
    dt.no-label:after {
      content: " ";
    }

    dd .alert {
      display: block;
      padding: 8px 15px;
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  .rescue-observed-group {
    div {
      display: block;
      position: relative;

      dd {
        left: 275px;
        position: relative;
      }
    }

    div:last-child {
      dd {
        left: 208px;
      }
    }
  }
}
