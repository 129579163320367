.ui.popup {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: .833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: .28571429rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);

  > .header {
    padding: 0;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 1.14285714em;
    line-height: 1.2;
    font-weight: 700;

    + .content {
      padding-top: .5em;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: .71428571em;
    height: .71428571em;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
    -webkit-box-shadow: 1px 1px 0 0 #bababc;
    box-shadow: 1px 1px 0 0 #bababc;
  }
}

#vpt [data-tooltip] {
  position: relative;

  &:before {
    pointer-events: none;
    position: absolute;
    content: '';
    font-size: 1rem;
    width: .71428571em;
    height: .71428571em;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
    -webkit-box-shadow: 1px 1px 0 0 #bababc;
    box-shadow: 1px 1px 0 0 #bababc;
  }

  &:after {
    pointer-events: none;
    content: attr(data-tooltip);
    position: absolute;
    text-transform: none;
    text-align: left;
    white-space: nowrap;
    font-size: 1rem;
    border: 1px solid #d4d4d5;
    line-height: 1.4285em;
    max-width: none;
    background: #fff;
    padding: .833em 1em;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    z-index: 1;
  }

  &:not([data-position]) {
    &:before {
      top: auto;
      right: auto;
      bottom: 100%;
      left: 50%;
      background: #fff;
      margin-left: -.07142857rem;
      margin-bottom: .14285714rem;
    }

    &:after {
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 100%;
      margin-bottom: .5em;
    }
  }

  &:after {
    pointer-events: none;
    visibility: hidden;
  }

  &:before {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0) !important;
    transform: rotate(45deg) scale(0) !important;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
  }

  &:after {
    opacity: 1;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
  }

  &:hover {
    &:after {
      visibility: visible;
      pointer-events: auto;
    }

    &:before {
      visibility: visible;
      pointer-events: auto;
      -webkit-transform: rotate(45deg) scale(1) !important;
      transform: rotate(45deg) scale(1) !important;
      opacity: 1;
    }
  }

  &:after, &[data-position="bottom center"]:after, &[data-position="top center"]:after {
    -webkit-transform: translateX(-50%) scale(0) !important;
    transform: translateX(-50%) scale(0) !important;
  }

  &:hover:after, &[data-position="bottom center"]:hover:after {
    -webkit-transform: translateX(-50%) scale(1) !important;
    transform: translateX(-50%) scale(1) !important;
  }

  &[data-position="left center"]:after, &[data-position="right center"]:after {
    -webkit-transform: translateY(-50%) scale(0) !important;
    transform: translateY(-50%) scale(0) !important;
  }

  &[data-position="left center"]:hover:after, &[data-position="right center"]:hover:after {
    -webkit-transform: translateY(-50%) scale(1) !important;
    transform: translateY(-50%) scale(1) !important;
  }

  &[data-position="bottom left"]:after, &[data-position="bottom right"]:after, &[data-position="top left"]:after, &[data-position="top right"]:after {
    -webkit-transform: scale(0) !important;
    transform: scale(0) !important;
  }

  &[data-position="bottom left"]:hover:after, &[data-position="bottom right"]:hover:after, &[data-position="top left"]:hover:after, &[data-position="top right"]:hover:after {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
  }

  &[data-inverted] {
    &:before {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      background: #1b1c1d;
    }

    &:after {
      background: #1b1c1d;
      color: #fff;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      .header {
        background-color: none;
        color: #fff;
      }
    }
  }
}

[data-position="top center"][data-tooltip] {
  &:after {
    top: auto;
    right: auto;
    left: 50%;
    bottom: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom: .5em;
  }

  &:before {
    top: auto;
    right: auto;
    bottom: 100%;
    left: 50%;
    background: #fff;
    margin-left: -.07142857rem;
    margin-bottom: .14285714rem;
  }
}

[data-position="top left"][data-tooltip] {
  &:after {
    top: auto;
    right: auto;
    left: 0;
    bottom: 100%;
    margin-bottom: .5em;
  }

  &:before {
    top: auto;
    right: auto;
    bottom: 100%;
    left: 1em;
    margin-left: -.07142857rem;
    margin-bottom: .14285714rem;
  }
}

[data-position="top right"][data-tooltip] {
  &:after {
    top: auto;
    left: auto;
    right: 0;
    bottom: 100%;
    margin-bottom: .5em;
  }

  &:before {
    top: auto;
    left: auto;
    bottom: 100%;
    right: 1em;
    margin-left: -.07142857rem;
    margin-bottom: .14285714rem;
  }
}

[data-position="bottom center"][data-tooltip] {
  &:after {
    bottom: auto;
    right: auto;
    left: 50%;
    top: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: .5em;
  }

  &:before {
    bottom: auto;
    right: auto;
    top: 100%;
    left: 50%;
    margin-left: -.07142857rem;
    margin-top: .14285714rem;
  }
}

[data-position="bottom left"][data-tooltip] {
  &:after {
    left: 0;
    top: 100%;
    margin-top: .5em;
  }

  &:before {
    bottom: auto;
    right: auto;
    top: 100%;
    left: 1em;
    margin-left: -.07142857rem;
    margin-top: .14285714rem;
  }
}

[data-position="bottom right"][data-tooltip] {
  &:after {
    right: 0;
    top: 100%;
    margin-top: .5em;
  }

  &:before {
    bottom: auto;
    left: auto;
    top: 100%;
    right: 1em;
    margin-left: -.14285714rem;
    margin-top: .07142857rem;
  }
}

[data-position="left center"][data-tooltip] {
  &:after {
    right: 100%;
    top: 50%;
    margin-right: .5em;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &:before {
    right: 100%;
    top: 50%;
    margin-top: -.14285714rem;
    margin-right: -.07142857rem;
  }
}

[data-position="right center"][data-tooltip] {
  &:after {
    left: 100%;
    top: 50%;
    margin-left: .5em;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &:before {
    left: 100%;
    top: 50%;
    margin-top: -.07142857rem;
    margin-left: .14285714rem;
  }
}

[data-position~=bottom][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px -1px 0 0 #bababc;
  box-shadow: -1px -1px 0 0 #bababc;
}

[data-position="left center"][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: 1px -1px 0 0 #bababc;
  box-shadow: 1px -1px 0 0 #bababc;
}

[data-position="right center"][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px 1px 0 0 #bababc;
  box-shadow: -1px 1px 0 0 #bababc;
}

[data-position~=top][data-tooltip]:before {
  background: #fff;
}

[data-inverted] {
  &[data-position~=bottom][data-tooltip]:before {
    background: #1b1c1d;
    -webkit-box-shadow: -1px -1px 0 0 #bababc;
    box-shadow: -1px -1px 0 0 #bababc;
  }

  &[data-position="left center"][data-tooltip]:before {
    background: #1b1c1d;
    -webkit-box-shadow: 1px -1px 0 0 #bababc;
    box-shadow: 1px -1px 0 0 #bababc;
  }

  &[data-position="right center"][data-tooltip]:before {
    background: #1b1c1d;
    -webkit-box-shadow: -1px 1px 0 0 #bababc;
    box-shadow: -1px 1px 0 0 #bababc;
  }

  &[data-position~=top][data-tooltip]:before {
    background: #1b1c1d;
  }
}

[data-position~=bottom][data-tooltip] {
  &:before {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  &:after {
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
}

[data-position="left center"][data-tooltip] {
  &:before {
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }

  &:after {
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

[data-position="right center"][data-tooltip] {
  &:before {
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }

  &:after {
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.ui {
  &.popup {
    margin: 0;
  }

  &.top {
    &.popup {
      margin: 0 0 .71428571em;
    }

    &.left.popup {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
    }

    &.center.popup {
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
    }

    &.right.popup {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
    }
  }

  &.left.center.popup {
    margin: 0 .71428571em 0 0;
    -webkit-transform-origin: right 50%;
    transform-origin: right 50%;
  }

  &.right.center.popup {
    margin: 0 0 0 .71428571em;
    -webkit-transform-origin: left 50%;
    transform-origin: left 50%;
  }

  &.bottom {
    &.popup {
      margin: .71428571em 0 0;
    }

    &.left.popup {
      -webkit-transform-origin: left top;
      transform-origin: left top;
    }

    &.center.popup {
      -webkit-transform-origin: center top;
      transform-origin: center top;
    }

    &.right.popup {
      -webkit-transform-origin: right top;
      transform-origin: right top;
    }

    &.center.popup:before {
      margin-left: -.30714286em;
      top: -.30714286em;
      left: 50%;
      right: auto;
      bottom: auto;
      -webkit-box-shadow: -1px -1px 0 0 #bababc;
      box-shadow: -1px -1px 0 0 #bababc;
    }

    &.left.popup {
      margin-left: 0;

      &:before {
        top: -.30714286em;
        left: 1em;
        right: auto;
        bottom: auto;
        margin-left: 0;
        -webkit-box-shadow: -1px -1px 0 0 #bababc;
        box-shadow: -1px -1px 0 0 #bababc;
      }
    }

    &.right.popup {
      margin-right: 0;

      &:before {
        top: -.30714286em;
        right: 1em;
        bottom: auto;
        left: auto;
        margin-left: 0;
        -webkit-box-shadow: -1px -1px 0 0 #bababc;
        box-shadow: -1px -1px 0 0 #bababc;
      }
    }
  }

  &.top {
    &.center.popup:before {
      top: auto;
      right: auto;
      bottom: -.30714286em;
      left: 50%;
      margin-left: -.30714286em;
    }

    &.left.popup {
      margin-left: 0;

      &:before {
        bottom: -.30714286em;
        left: 1em;
        top: auto;
        right: auto;
        margin-left: 0;
      }
    }

    &.right.popup {
      margin-right: 0;

      &:before {
        bottom: -.30714286em;
        right: 1em;
        top: auto;
        left: auto;
        margin-left: 0;
      }
    }
  }

  &.left.center.popup:before {
    top: 50%;
    right: -.30714286em;
    bottom: auto;
    left: auto;
    margin-top: -.30714286em;
    -webkit-box-shadow: 1px -1px 0 0 #bababc;
    box-shadow: 1px -1px 0 0 #bababc;
  }

  &.right.center.popup:before {
    top: 50%;
    left: -.30714286em;
    bottom: auto;
    right: auto;
    margin-top: -.30714286em;
    -webkit-box-shadow: -1px 1px 0 0 #bababc;
    box-shadow: -1px 1px 0 0 #bababc;
  }

  &.bottom.popup:before, &.left.center.popup:before, &.right.center.popup:before, &.top.popup:before {
    background: #fff;
  }

  &.inverted {
    &.bottom.popup:before, &.left.center.popup:before, &.right.center.popup:before, &.top.popup:before {
      background: #1b1c1d;
    }
  }

  &.popup > .ui.grid:not(.padded) {
    width: calc(100% + 1.75rem);
    margin: -.7rem -.875rem;
  }

  &.loading.popup {
    display: block;
    visibility: hidden;
    z-index: -1;
  }

  &.animating.popup {
    display: block;
  }

  &.visible.popup {
    display: block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &.basic.popup:before {
    display: none;
  }

  &.wide.popup {
    max-width: 350px;
  }

  &[class*="very wide"].popup {
    max-width: 550px;
  }

  &.fluid.popup {
    width: 100%;
    max-width: none;
  }

  &.inverted.popup {
    background: #1b1c1d;
    color: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;

    .header {
      background-color: none;
      color: #fff;
    }

    &:before {
      background-color: #1b1c1d;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }

  &.flowing.popup {
    max-width: none;
  }

  &.mini.popup {
    font-size: .78571429rem;
  }

  &.tiny.popup {
    font-size: .85714286rem;
  }

  &.small.popup {
    font-size: .92857143rem;
  }

  &.popup {
    font-size: 1rem;
  }

  &.large.popup {
    font-size: 1.14285714rem;
  }

  &.huge.popup {
    font-size: 1.42857143rem;
  }
}

@media only screen and (max-width: 767px) {
  .ui {
    &.wide.popup, &[class*="very wide"].popup {
      max-width: 250px;
    }
  }
}
