.add-disease-modal {
    .modal-body {
        font-size: 14px;
        word-break: normal;
        padding: 2rem;

        ol {
            padding-left: 15px;

            li {
                display: list-item;
                margin-top: 10px;
                padding: 0;
                font-weight: bold;

                span {
                    font-weight: normal;
                }
            }
        }

        .free-text-prompt {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }

        .free-text-notice {
            margin-top: 5rem;
        }

        .mondo-input-field,
        .instruction-references {
            margin-top: 10px;
        }

        .retrieve-button {
            float: right;
            margin-top: 10px;
        }

        .retrieved-disease-section {
            border: 1px solid #ddd;
            border-radius: 2px;
            margin-top: 5rem;
            padding: 10px;
        }

        .back-link-button {
            margin-top: -10px;
            margin-left: -10px;
        }

        .form-row {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        label {
            font-weight: bold;
        }
    }
}