.ui.label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 .14285714em;
  background-color: #e8e8e8;
  background-image: none;
  padding: .5833em .833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: .28571429rem;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

a.ui.label {
  cursor: pointer;
}

.ui {
  &.label > {
    a {
      cursor: pointer;
      color: inherit;
      opacity: .5;
      -webkit-transition: .1s opacity ease;
      transition: .1s opacity ease;

      &:hover {
        opacity: 1;
      }
    }

    img {
      width: auto !important;
      vertical-align: middle;
      height: 2.1666em !important;
    }

    .icon {
      width: auto;
      margin: 0 .75em 0 0;
    }

    .detail {
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      margin-left: 1em;
      opacity: .8;

      .icon {
        margin: 0 .25em 0 0;
      }
    }

    .close.icon {
      cursor: pointer;
      margin-right: 0;
      margin-left: .5em;
      font-size: .92857143em;
      opacity: .5;
      -webkit-transition: background .1s ease;
      transition: background .1s ease;
    }

    .delete.icon {
      cursor: pointer;
      margin-right: 0;
      margin-left: .5em;
      font-size: .92857143em;
      opacity: .5;
      -webkit-transition: background .1s ease;
      transition: background .1s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.labels > .label {
    margin: 0 .5em .5em 0;
  }

  &.header > .ui.label {
    margin-top: -.29165em;
  }

  &.attached.segment > .ui.top.left.attached.label, &.bottom.attached.segment > .ui.top.left.attached.label {
    border-top-left-radius: 0;
  }

  &.attached.segment > .ui.top.right.attached.label, &.bottom.attached.segment > .ui.top.right.attached.label {
    border-top-right-radius: 0;
  }

  &.top.attached {
    &.segment > .ui.bottom {
      &.left.attached.label {
        border-bottom-left-radius: 0;
      }

      &.right.attached.label {
        border-bottom-right-radius: 0;
      }
    }

    &.label {
      + [class*="right floated"] + *, &:first-child + :not(.attached) {
        margin-top: 2rem !important;
      }
    }
  }

  &.bottom.attached.label:first-child ~ :last-child:not(.attached) {
    margin-top: 0;
    margin-bottom: 2rem !important;
  }

  &.image.label {
    width: auto !important;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 9999px;
    vertical-align: baseline;
    text-transform: none;
    background: #e8e8e8;
    padding: .5833em .833em .5833em .5em;
    border-radius: .28571429rem;
    -webkit-box-shadow: none;
    box-shadow: none;

    img {
      display: inline-block;
      vertical-align: top;
      height: 2.1666em;
      margin: -.5833em .5em -.5833em -.5em;
      border-radius: .28571429rem 0 0 .28571429rem;
    }

    .detail {
      background: rgba(0, 0, 0, 0.1);
      margin: -.5833em -.833em -.5833em .5em;
      padding: .5833em .833em;
      border-radius: 0 .28571429rem .28571429rem 0;
    }
  }

  &.tag {
    &.label, &.labels .label {
      margin-left: 1em;
      position: relative;
      padding-left: 1.5em;
      padding-right: 1.5em;
      border-radius: 0 .28571429rem .28571429rem 0;
      -webkit-transition: none;
      transition: none;
    }

    &.label:before, &.labels .label:before {
      position: absolute;
      -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
      transform: translateY(-50%) translateX(50%) rotate(-45deg);
      top: 50%;
      right: 100%;
      content: '';
      background-color: inherit;
      background-image: none;
      width: 1.56em;
      height: 1.56em;
      -webkit-transition: none;
      transition: none;
    }

    &.label:after, &.labels .label:after {
      position: absolute;
      content: '';
      top: 50%;
      left: -.25em;
      margin-top: -.25em;
      background-color: #fff !important;
      width: .5em;
      height: .5em;
      -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.3);
      border-radius: 500rem;
    }
  }

  &.corner.label {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    border-color: #e8e8e8;
    width: 4em;
    height: 4em;
    z-index: 1;
    -webkit-transition: border-color .1s ease;
    transition: border-color .1s ease;
    background-color: transparent !important;

    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      z-index: -1;
      width: 0;
      height: 0;
      background-color: transparent !important;
      border-top: 0 solid transparent;
      border-right: 4em solid transparent;
      border-bottom: 4em solid transparent;
      border-left: 0 solid transparent;
      border-right-color: inherit;
      -webkit-transition: border-color .1s ease;
      transition: border-color .1s ease;
    }

    .icon {
      cursor: default;
      position: relative;
      top: .64285714em;
      left: .78571429em;
      font-size: 1.14285714em;
      margin: 0;
    }
  }

  &.left.corner.label {
    right: auto;
    left: 0;

    &:after {
      right: auto;
      left: 0;
      border-top: 4em solid transparent;
      border-right: 4em solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 0 solid transparent;
      border-top-color: inherit;
    }

    .icon {
      left: -.78571429em;
    }
  }

  &.segment > .ui {
    &.corner.label {
      top: -1px;
      right: -1px;
    }

    &.left.corner.label {
      right: auto;
      left: -1px;
    }
  }

  &.ribbon.label {
    position: relative;
    margin: 0;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    border-radius: 0 .28571429rem .28571429rem 0;
    border-color: rgba(0, 0, 0, 0.15);

    &:after {
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      background-color: transparent !important;
      border-style: solid;
      border-width: 0 1.2em 1.2em 0;
      border-color: transparent;
      border-right-color: inherit;
      width: 0;
      height: 0;
    }

    left: calc(-1rem - 1.2em);
    margin-right: -1.2em;
    padding-left: calc(1rem + 1.2em);
    padding-right: 1.2em;
  }

  &[class*="right ribbon"].label {
    left: calc(100% + 1rem + 1.2em);
    padding-left: 1.2em;
    padding-right: calc(1rem + 1.2em);
    text-align: left;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    border-radius: .28571429rem 0 0 .28571429rem;

    &:after {
      left: auto;
      right: 0;
      border-style: solid;
      border-width: 1.2em 1.2em 0 0;
      border-color: transparent;
      border-top-color: inherit;
    }
  }

  &.card .image > .ribbon.label, &.image > .ribbon.label {
    position: absolute;
    top: 1rem;
  }

  &.card .image > .ui.ribbon.label, &.image > .ui.ribbon.label {
    left: calc(--.05rem - 1.2em);
  }

  &.card .image > .ui[class*="right ribbon"].label, &.image > .ui[class*="right ribbon"].label {
    left: calc(100% + -.05rem + 1.2em);
    padding-left: .833em;
  }

  &.table td > .ui {
    &.ribbon.label {
      left: calc(-.78571429em - 1.2em);
    }

    &[class*="right ribbon"].label {
      left: calc(100% + .78571429em + 1.2em);
      padding-left: .833em;
    }
  }

  &.attached.label, &[class*="top attached"].label {
    width: 100%;
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    padding: .75em 1em;
    border-radius: .21428571rem .21428571rem 0 0;
  }

  &[class*="bottom attached"].label {
    top: auto;
    bottom: 0;
    border-radius: 0 0 .21428571rem .21428571rem;
  }

  &[class*="top left attached"].label {
    width: auto;
    margin-top: 0 !important;
    border-radius: .21428571rem 0 .28571429rem 0;
  }

  &[class*="top right attached"].label {
    width: auto;
    left: auto;
    right: 0;
    border-radius: 0 .21428571rem 0 .28571429rem;
  }

  &[class*="bottom left attached"].label {
    width: auto;
    top: auto;
    bottom: 0;
    border-radius: 0 .28571429rem 0 .21428571rem;
  }

  &[class*="bottom right attached"].label {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    width: auto;
    border-radius: .28571429rem 0 .21428571rem 0;
  }

  &.label.disabled {
    opacity: .5;
  }
}

a.ui {
  &.label:hover, &.labels .label:hover {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    background-image: none;
    color: rgba(0, 0, 0, 0.8);
  }
}

.ui.labels a.label:hover:before, a.ui.label:hover:before {
  color: rgba(0, 0, 0, 0.8);
}

.ui.active.label {
  background-color: #d0d0d0;
  border-color: #d0d0d0;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);

  &:before {
    background-color: #d0d0d0;
    background-image: none;
    color: rgba(0, 0, 0, 0.95);
  }
}

a.ui {
  &.active.label:hover, &.labels .active.label:hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    background-image: none;
    color: rgba(0, 0, 0, 0.95);
  }
}

.ui.labels a.active.label:ActiveHover:before, a.ui.active.label:ActiveHover:before {
  background-color: #c8c8c8;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}

.ui {
  &.label.visible:not(.dropdown), &.labels.visible .label {
    display: inline-block !important;
  }

  &.label.hidden, &.labels.hidden .label {
    display: none !important;
  }

  &.red {
    &.label {
      background-color: #db2828 !important;
      border-color: #db2828 !important;
      color: #fff !important;
    }

    &.labels .label {
      background-color: #db2828 !important;
      border-color: #db2828 !important;
      color: #fff !important;

      &:hover {
        background-color: #d01919 !important;
        border-color: #d01919 !important;
        color: #fff !important;
      }
    }
  }
}

a.ui.red.label:hover {
  background-color: #d01919 !important;
  border-color: #d01919 !important;
  color: #fff !important;
}

.ui {
  &.red {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #b21e1e !important;
    }
  }

  &.basic.red {
    &.label {
      background: none #fff !important;
      color: #db2828 !important;
      border-color: #db2828 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #d01919 !important;
      border-color: #d01919 !important;
    }
  }
}

a.ui.basic.red.label:hover {
  background-color: #fff !important;
  color: #d01919 !important;
  border-color: #d01919 !important;
}

.ui.orange {
  &.label {
    background-color: #f2711c !important;
    border-color: #f2711c !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #f2711c !important;
    border-color: #f2711c !important;
    color: #fff !important;

    &:hover {
      background-color: #f26202 !important;
      border-color: #f26202 !important;
      color: #fff !important;
    }
  }
}

a.ui.orange.label:hover {
  background-color: #f26202 !important;
  border-color: #f26202 !important;
  color: #fff !important;
}

.ui {
  &.orange {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #cf590c !important;
    }
  }

  &.basic.orange {
    &.label {
      background: none #fff !important;
      color: #f2711c !important;
      border-color: #f2711c !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #f26202 !important;
      border-color: #f26202 !important;
    }
  }
}

a.ui.basic.orange.label:hover {
  background-color: #fff !important;
  color: #f26202 !important;
  border-color: #f26202 !important;
}

.ui.yellow {
  &.label {
    background-color: #fbbd08 !important;
    border-color: #fbbd08 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #fbbd08 !important;
    border-color: #fbbd08 !important;
    color: #fff !important;

    &:hover {
      background-color: #eaae00 !important;
      border-color: #eaae00 !important;
      color: #fff !important;
    }
  }
}

a.ui.yellow.label:hover {
  background-color: #eaae00 !important;
  border-color: #eaae00 !important;
  color: #fff !important;
}

.ui {
  &.yellow {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #cd9903 !important;
    }
  }

  &.basic.yellow {
    &.label {
      background: none #fff !important;
      color: #fbbd08 !important;
      border-color: #fbbd08 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #eaae00 !important;
      border-color: #eaae00 !important;
    }
  }
}

a.ui.basic.yellow.label:hover {
  background-color: #fff !important;
  color: #eaae00 !important;
  border-color: #eaae00 !important;
}

.ui.olive {
  &.label {
    background-color: #b5cc18 !important;
    border-color: #b5cc18 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #b5cc18 !important;
    border-color: #b5cc18 !important;
    color: #fff !important;

    &:hover {
      background-color: #a7bd0d !important;
      border-color: #a7bd0d !important;
      color: #fff !important;
    }
  }
}

a.ui.olive.label:hover {
  background-color: #a7bd0d !important;
  border-color: #a7bd0d !important;
  color: #fff !important;
}

.ui {
  &.olive {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #198f35 !important;
    }
  }

  &.basic.olive {
    &.label {
      background: none #fff !important;
      color: #b5cc18 !important;
      border-color: #b5cc18 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #a7bd0d !important;
      border-color: #a7bd0d !important;
    }
  }
}

a.ui.basic.olive.label:hover {
  background-color: #fff !important;
  color: #a7bd0d !important;
  border-color: #a7bd0d !important;
}

.ui.green {
  &.label {
    background-color: #21ba45 !important;
    border-color: #21ba45 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #21ba45 !important;
    border-color: #21ba45 !important;
    color: #fff !important;

    &:hover {
      background-color: #16ab39 !important;
      border-color: #16ab39 !important;
      color: #fff !important;
    }
  }
}

a.ui.green.label:hover {
  background-color: #16ab39 !important;
  border-color: #16ab39 !important;
  color: #fff !important;
}

.ui {
  &.green {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #198f35 !important;
    }
  }

  &.basic.green {
    &.label {
      background: none #fff !important;
      color: #21ba45 !important;
      border-color: #21ba45 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #16ab39 !important;
      border-color: #16ab39 !important;
    }
  }
}

a.ui.basic.green.label:hover {
  background-color: #fff !important;
  color: #16ab39 !important;
  border-color: #16ab39 !important;
}

.ui.teal {
  &.label {
    background-color: #00b5ad !important;
    border-color: #00b5ad !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #00b5ad !important;
    border-color: #00b5ad !important;
    color: #fff !important;

    &:hover {
      background-color: #009c95 !important;
      border-color: #009c95 !important;
      color: #fff !important;
    }
  }
}

a.ui.teal.label:hover {
  background-color: #009c95 !important;
  border-color: #009c95 !important;
  color: #fff !important;
}

.ui {
  &.teal {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #00827c !important;
    }
  }

  &.basic.teal {
    &.label {
      background: none #fff !important;
      color: #00b5ad !important;
      border-color: #00b5ad !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #009c95 !important;
      border-color: #009c95 !important;
    }
  }
}

a.ui.basic.teal.label:hover {
  background-color: #fff !important;
  color: #009c95 !important;
  border-color: #009c95 !important;
}

.ui.blue {
  &.label {
    background-color: #2185d0 !important;
    border-color: #2185d0 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #2185d0 !important;
    border-color: #2185d0 !important;
    color: #fff !important;

    &:hover {
      background-color: #1678c2 !important;
      border-color: #1678c2 !important;
      color: #fff !important;
    }
  }
}

a.ui.blue.label:hover {
  background-color: #1678c2 !important;
  border-color: #1678c2 !important;
  color: #fff !important;
}

.ui {
  &.blue {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #1a69a4 !important;
    }
  }

  &.basic.blue {
    &.label {
      background: none #fff !important;
      color: #2185d0 !important;
      border-color: #2185d0 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #1678c2 !important;
      border-color: #1678c2 !important;
    }
  }
}

a.ui.basic.blue.label:hover {
  background-color: #fff !important;
  color: #1678c2 !important;
  border-color: #1678c2 !important;
}

.ui.violet {
  &.label {
    background-color: #6435c9 !important;
    border-color: #6435c9 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #6435c9 !important;
    border-color: #6435c9 !important;
    color: #fff !important;

    &:hover {
      background-color: #5829bb !important;
      border-color: #5829bb !important;
      color: #fff !important;
    }
  }
}

a.ui.violet.label:hover {
  background-color: #5829bb !important;
  border-color: #5829bb !important;
  color: #fff !important;
}

.ui {
  &.violet {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #502aa1 !important;
    }
  }

  &.basic.violet {
    &.label {
      background: none #fff !important;
      color: #6435c9 !important;
      border-color: #6435c9 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #5829bb !important;
      border-color: #5829bb !important;
    }
  }
}

a.ui.basic.violet.label:hover {
  background-color: #fff !important;
  color: #5829bb !important;
  border-color: #5829bb !important;
}

.ui.purple {
  &.label {
    background-color: #a333c8 !important;
    border-color: #a333c8 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #a333c8 !important;
    border-color: #a333c8 !important;
    color: #fff !important;

    &:hover {
      background-color: #9627ba !important;
      border-color: #9627ba !important;
      color: #fff !important;
    }
  }
}

a.ui.purple.label:hover {
  background-color: #9627ba !important;
  border-color: #9627ba !important;
  color: #fff !important;
}

.ui {
  &.purple {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #82299f !important;
    }
  }

  &.basic.purple {
    &.label {
      background: none #fff !important;
      color: #a333c8 !important;
      border-color: #a333c8 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #9627ba !important;
      border-color: #9627ba !important;
    }
  }
}

a.ui.basic.purple.label:hover {
  background-color: #fff !important;
  color: #9627ba !important;
  border-color: #9627ba !important;
}

.ui.pink {
  &.label {
    background-color: #e03997 !important;
    border-color: #e03997 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #e03997 !important;
    border-color: #e03997 !important;
    color: #fff !important;

    &:hover {
      background-color: #e61a8d !important;
      border-color: #e61a8d !important;
      color: #fff !important;
    }
  }
}

a.ui.pink.label:hover {
  background-color: #e61a8d !important;
  border-color: #e61a8d !important;
  color: #fff !important;
}

.ui {
  &.pink {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #c71f7e !important;
    }
  }

  &.basic.pink {
    &.label {
      background: none #fff !important;
      color: #e03997 !important;
      border-color: #e03997 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #e61a8d !important;
      border-color: #e61a8d !important;
    }
  }
}

a.ui.basic.pink.label:hover {
  background-color: #fff !important;
  color: #e61a8d !important;
  border-color: #e61a8d !important;
}

.ui.brown {
  &.label {
    background-color: #a5673f !important;
    border-color: #a5673f !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #a5673f !important;
    border-color: #a5673f !important;
    color: #fff !important;

    &:hover {
      background-color: #975b33 !important;
      border-color: #975b33 !important;
      color: #fff !important;
    }
  }
}

a.ui.brown.label:hover {
  background-color: #975b33 !important;
  border-color: #975b33 !important;
  color: #fff !important;
}

.ui {
  &.brown {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #805031 !important;
    }
  }

  &.basic.brown {
    &.label {
      background: none #fff !important;
      color: #a5673f !important;
      border-color: #a5673f !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #975b33 !important;
      border-color: #975b33 !important;
    }
  }
}

a.ui.basic.brown.label:hover {
  background-color: #fff !important;
  color: #975b33 !important;
  border-color: #975b33 !important;
}

.ui.grey {
  &.label {
    background-color: #767676 !important;
    border-color: #767676 !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #767676 !important;
    border-color: #767676 !important;
    color: #fff !important;

    &:hover {
      background-color: #838383 !important;
      border-color: #838383 !important;
      color: #fff !important;
    }
  }
}

a.ui.grey.label:hover {
  background-color: #838383 !important;
  border-color: #838383 !important;
  color: #fff !important;
}

.ui {
  &.grey {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #805031 !important;
    }
  }

  &.basic.grey {
    &.label {
      background: none #fff !important;
      color: #767676 !important;
      border-color: #767676 !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #838383 !important;
      border-color: #838383 !important;
    }
  }
}

a.ui.basic.grey.label:hover {
  background-color: #fff !important;
  color: #838383 !important;
  border-color: #838383 !important;
}

.ui.black {
  &.label {
    background-color: #1b1c1d !important;
    border-color: #1b1c1d !important;
    color: #fff !important;
  }

  &.labels .label {
    background-color: #1b1c1d !important;
    border-color: #1b1c1d !important;
    color: #fff !important;

    &:hover {
      background-color: #27292a !important;
      border-color: #27292a !important;
      color: #fff !important;
    }
  }
}

a.ui.black.label:hover {
  background-color: #27292a !important;
  border-color: #27292a !important;
  color: #fff !important;
}

.ui {
  &.black {
    &.corner.label {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    &.ribbon.label {
      border-color: #805031 !important;
    }
  }

  &.basic.black {
    &.label {
      background: none #fff !important;
      color: #1b1c1d !important;
      border-color: #1b1c1d !important;
    }

    &.labels a.label:hover {
      background-color: #fff !important;
      color: #27292a !important;
      border-color: #27292a !important;
    }
  }
}

a.ui.basic.black.label:hover {
  background-color: #fff !important;
  color: #27292a !important;
  border-color: #27292a !important;
}

.ui.basic.label {
  background: none #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.ui.basic.label:hover {
  text-decoration: none;
  background: none #fff;
  color: #1e70bf;
  -webkit-box-shadow: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui {
  &.basic.pointing.label:before {
    border-color: inherit;
  }

  &.fluid.labels > .label, &.label.fluid {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &.inverted {
    &.label, &.labels .label {
      color: rgba(255, 255, 255, 0.9) !important;
    }
  }

  &.horizontal {
    &.label, &.labels .label {
      margin: 0 .5em 0 0;
      padding: .4em .833em;
      min-width: 3em;
      text-align: center;
    }
  }

  &.circular {
    &.label, &.labels .label {
      min-width: 2em;
      min-height: 2em;
      padding: 0.5em !important;
      line-height: 1em;
      text-align: center;
      border-radius: 500rem;
    }
  }

  &.empty.circular {
    &.label, &.labels .label {
      min-width: 0;
      min-height: 0;
      overflow: hidden;
      width: .5em;
      height: .5em;
      vertical-align: baseline;
    }
  }

  &.pointing.label {
    position: relative;
  }

  &.attached.pointing.label {
    position: absolute;
  }

  &.pointing.label {
    &:before {
      background-color: inherit;
      background-image: inherit;
      border-width: none;
      border-style: solid;
      border-color: inherit;
      position: absolute;
      content: '';
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background-image: none;
      z-index: 2;
      width: .6666em;
      height: .6666em;
      -webkit-transition: background .1s ease;
      transition: background .1s ease;
    }

    margin-top: 1em;
  }

  &[class*="pointing above"].label {
    margin-top: 1em;
  }

  &.pointing.label:before, &[class*="pointing above"].label:before {
    border-width: 1px 0 0 1px;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
  }

  &[class*="bottom pointing"].label, &[class*="pointing below"].label {
    margin-top: 0;
    margin-bottom: 1em;
  }

  &[class*="bottom pointing"].label:before, &[class*="pointing below"].label:before {
    border-width: 0 1px 1px 0;
    top: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
  }

  &[class*="left pointing"].label {
    margin-top: 0;
    margin-left: .6666em;

    &:before {
      border-width: 0 0 1px 1px;
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      bottom: auto;
      right: auto;
      top: 50%;
      left: 0;
    }
  }

  &[class*="right pointing"].label {
    margin-top: 0;
    margin-right: .6666em;

    &:before {
      border-width: 1px 1px 0 0;
      -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
    }
  }

  &.basic {
    &.pointing.label:before, &[class*="pointing above"].label:before {
      margin-top: -1px;
    }

    &[class*="bottom pointing"].label:before, &[class*="pointing below"].label:before {
      bottom: auto;
      top: 100%;
      margin-top: 1px;
    }

    &[class*="left pointing"].label:before {
      top: 50%;
      left: -1px;
    }

    &[class*="right pointing"].label:before {
      top: 50%;
      right: -1px;
    }
  }

  &.floating.label {
    position: absolute;
    z-index: 100;
    top: -1em;
    left: 100%;
    margin: 0 0 0 -1.5em !important;
  }

  &.mini {
    &.label, &.labels .label {
      font-size: .64285714rem;
    }
  }

  &.tiny {
    &.label, &.labels .label {
      font-size: .71428571rem;
    }
  }

  &.small {
    &.label, &.labels .label {
      font-size: .78571429rem;
    }
  }

  &.label, &.labels .label {
    font-size: .85714286rem;
  }

  &.large {
    &.label, &.labels .label {
      font-size: 1rem;
    }
  }

  &.big {
    &.label, &.labels .label {
      font-size: 1.28571429rem;
    }
  }

  &.huge {
    &.label, &.labels .label {
      font-size: 1.42857143rem;
    }
  }

  &.massive {
    &.label, &.labels .label {
      font-size: 1.71428571rem;
    }
  }
}